<template>
  <div class="flex justify-between align-middle relative w-full p-5">
    <div v-if="!!label" class="h-10 gap-3">
      <p class="font-inter text-base leading-5 font-normal">{{ label }}</p>
    </div>

    <ion-checkbox
      @ionChange="toggle"
      :checked="checked"
      :disabled="disabled"
      :value="label"
      mode="md"
      class="my-1.5 rounded w-5 h-5"
    >
    </ion-checkbox>
  </div>
</template>
<script>
import { IonCheckbox } from "@ionic/vue";

export default {
  components: {
    IonCheckbox,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["change"],
  methods: {
    toggle(e) {
      this.$emit("change",e.target.checked,e.target.value);
    },
  },
};
</script>

<style scoped>
ion-checkbox {
  --height: 24px;
  --width: 24px;
  border-end-end-radius: 4px;
  --checkmark-color: #ffffff;
  --border-color: #E3E5E5;
}
</style>
