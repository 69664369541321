<template>
  <page-layout title="Feed Settings" linkDefault="">
    <div class="flex flex-col w-full items-center justify-center overflow-x-scroll  ">
      <div class="flex 
     flex-row-reverse 
     justify-between
     align-middle
     relative
     w-full
     p-3">
        <Button class="font-inter font-medium text-lg leading-4" @click="reset" :size="normal" :color="primary"
          shape="ghost">Reset</Button>
      </div>


      <div class="flex 
     flex-row 
     justify-between
     align-middle
     relative
     w-full
     p-5">

        <div class="flex flex-col">
          <p class="font-inter
      font-bold
      text-base
      leading-5 ">
            Sort By</p>

          <p v-if="Relevance" class="text-regular
    text-gray-600 ">
            Relevance</p>

          <p v-if="Hottest" class="text-regular
      text-gray-600 ">
            Hottest</p>

          <p v-if="MostRecent" class="text-regular
    text-gray-600 ">
            Most Recent</p>
        </div>
        <vue-feather type="chevron-down"></vue-feather>
      </div>



      <check-box :checked="Relevance" label="Relevance" @change="applyfilter" />
      <check-box :checked="Hottest" label="Hottest" @change="applyfilter" />
      <check-box :checked="MostRecent" label="Most Recent" @change="applyfilter" />

      <hr class="">


      <div class="flex flex-row justify-between align-middle relative w-full p-5">
        <div class="flex flex-col">

          <p class="font-inter
     font-bold 
     text-base 
     leading-5 ">
            Status</p>

          <p class="text-regular
     text-gray-600 " v-if="AnsweredOnly">
            Answered only</p>

          <p class="text-regular
   text-gray-600 " v-if="UnAnsweredOnly">
            Unanswered only</p>

          <p class="text-regular
       text-gray-600 " v-if="AnsweredAndUnAnswered">
            Answered & unanswered</p>
        </div>
        <vue-feather type="chevron-down"></vue-feather>
      </div>

      <check-box :checked="AnsweredOnly" label="Answered only" @change="applyfilter" />
      <check-box :checked="UnAnsweredOnly" label="Unanswered only" @change="applyfilter" />
      <check-box :checked="AnsweredAndUnAnswered" label="Answered & unanswered" @change="applyfilter" />

      <hr class="">
      <div class="flex-row flex justify-between align-middle relative w-full p-5">
        <p class="font-inter font-bold text-base leading-5 "> Show posts from other universities</p>
        <toggle :active="toggleStatus" @switch="switched" />
      </div>
      <hr class="">
      <div class="px-4 w-full">
        <Button class="my-4" color="primary" shape="full">Apply filters </Button>
      </div>
    </div>
  </page-layout>
</template>

<script>
import pageLayout from "../../components/base/pageLayout.vue";
import Button from "../../components/Feed/Button.vue";
import CheckBox from '../../components/CheckBox.vue'
import toggle from '../../components/toggle.vue'
export default {
  components: { CheckBox, toggle, Button, pageLayout },
  data() {
    return {
      Relevance: true,
      Hottest: false,
      MostRecent: false,
      Sortby: 'Relevance',

      AnsweredOnly: false,
      UnAnsweredOnly: false,
      AnsweredAndUnAnswered: true,
      Status: 'Answered only',
      toggleStatus: false,
    }
  },

  methods: {

    applyfilter(checked, label) {
      if (checked == true && label == 'Relevance') {
        this.Relevance = true;
        this.Hottest = false;
        this.MostRecent = false;
        this.Sortby = 'Relevance';
      }
      if (checked == true && label == 'Hottest') {
        this.Relevance = false;
        this.Hottest = true;
        this.MostRecent = false;
        this.Sortby = 'Hottest';

      }
      if (checked == true && label == 'Most Recent') {
        this.Relevance = false;
        this.Hottest = false;
        this.MostRecent = true;
        this.Sortby = 'Most Recent';
      }


      if (checked == true && label == 'Answered only') {
        this.AnsweredOnly = true;
        this.UnAnsweredOnly = false;
        this.AnsweredAndUnAnswered = false;
        this.Status = 'Answered only';
      }
      if (checked == true && label == 'Unanswered only') {
        this.AnsweredOnly = false;
        this.UnAnsweredOnly = true;
        this.AnsweredAndUnAnswered = false;
        this.Status = 'Unanswered only';
      }
      if (checked == true && label == 'Answered & unanswered') {
        this.AnsweredOnly = false;
        this.UnAnsweredOnly = false;
        this.AnsweredAndUnAnswered = true;
        this.Status = 'Answered & unanswered';
      }


    },

    switched(checked) {
      this.toggleStatus = checked;
    },
    reset() {
      this.toggleStatus = true;
      this.Relevance = true;
      this.Hottest = false;
      this.MostRecent = false;
      this.Sortby = 'Relevance';
      this.AnsweredOnly = false;
      this.UnAnsweredOnly = false;
      this.AnsweredAndUnAnswered = true;
      this.Status = 'Answered & unanswered';
    }
  },
}
</script>
